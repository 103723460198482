<template>
    <div>Esporta Certificati Attestazione APS</div>
</template>

<script>
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

 export default {
     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Comitati", route: "/committee/index" },
             { title: "Certificati Attestazione APS", route: "/protocol/index" },
             { title: "Esporta Certificati Attestazione APS" }
         ]);
     }
 };
</script>
